import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Functional Testing for IoT Products",
  "description": "Functional Testing for IoT products: which parts to test, what tools to use, and the each methods and objectives. ",
  "author": "Jacen Wang",
  "categories": [],
  "date": "2022-10-18T16:00:00.000Z",
  "featured": false,
  "tags": ["ee"],
  "relatedArticles": [""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Functional testing is a method of assessment to test if the features and performance of a product meet the required functional standards. These standards are measured based on the product’s features, operation descriptions, and user solutions.`}</p>
    <p>{`Functional tests can be divided into two broad categories, `}<strong parentName="p">{`hardware functional testing,`}</strong>{` and `}<strong parentName="p">{`software functional testing`}</strong>{`. The two differ in terms of objectives, tools, and methods.`}</p>
    <blockquote>
      <p parentName="blockquote">{`An IoT product is made up of a combination of hardware and software.`}</p>
    </blockquote>
    <p>{`We’ll first discuss the hardware functional testing of IoT products.`}</p>
    <p>{`From its definition, the process of hardware testing in an IoT product should start by getting the `}<strong parentName="p">{`product features (specifications), operation descriptions (function descriptions),`}</strong>{` and `}<strong parentName="p">{`user solutions (logical frameworks).`}</strong></p>
    <p>{`As a trusted manufacturing and development partner for novel IoT products, NexPCB has an extensive background in handling IoT products.`}</p>
    <p>{`An IoT product generally has a feature of `}<strong parentName="p">{`wired or wireless network functions`}</strong>{`, followed by a `}<strong parentName="p">{`master control`}</strong>{` part with capabilities of `}<strong parentName="p">{`computing or storage`}</strong>{` and often an `}<strong parentName="p">{`energy supply`}</strong>{` part such as power supply and batteries, and there usually will be some `}<strong parentName="p">{`manipulation structure`}</strong>{` such as keys and switches, or interfaces, and some additional display, LED, speakers, and other display units, as well as sensors and other information input units. All these are a bit similar to the peripheral configuration of a computer, and below we will have a brief discussion on them.`}</p>
    <h3 {...{
      "id": ""
    }}></h3>
    <p>{`1`}{`.`}{` Network Functional Testing`}</p>
    <ol>
      <li parentName="ol">{`Wired network tests are one of the most conventional methods of functional testing. The assessment is done by connecting a network cable to the LAN switch, then using frequently-used network tools or ping commands on the computer.`}</li>
      <li parentName="ol">{`Wireless networks then require different tools and methods depending on the category of wireless technology`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Wifi requires a Wifi router.`}</li>
      <li parentName="ul">{`Bluetooth needs to use a mobile phone app or the Bluetooth testing tool on the computer.`}</li>
      <li parentName="ul">{`The most common method for cellular communication is access points. If a product doesn’t have a display or operation interface, and no local network is present, it’s usually tested comprehensively using the analog base station mode.`}</li>
      <li parentName="ul">{`Lora, ZigBee, NB-IOT, and other wireless methods need the corresponding gateway and server configured to test their connections.`}</li>
    </ul>
    <h3 {...{
      "id": "2-master-control"
    }}>{`2. Master Control`}</h3>
    <p>{`Any installed tools and methods in an IoT product's firmware are usually determined by the designer in the design phase. These will be stated in a `}<em parentName="p">{`guide/manual.`}</em>{` `}</p>
    <p>{`However, even for the processors with the same ARM architecture and same kernel, the tools used vary widely based on manufacturers and design solutions. So, we usually need a master control system to download various tools, programs, and testing software. We also often need to make a customized download or testing device for the needs of mass production.`}</p>
    <h3 {...{
      "id": "3-power-supply"
    }}>{`3. Power Supply`}</h3>
    <ol>
      <li parentName="ol">{`If the product comes with an `}<strong parentName="li">{`adapter`}</strong>{`: functional testing, related certification, and reliability testing shall be arranged for the adapter.`}</li>
      <li parentName="ol">{`If the product carries a `}<strong parentName="li">{`battery`}</strong>{`: the single-cell testing shall be carried out by a battery tester. After passing the test, it is assembled into the whole machine for a complete charge-discharge and operating mode test. Meanwhile, attention shall be paid to heat generation, power consumption, etc.`}</li>
      <li parentName="ol">{`The power supply of the `}<strong parentName="li">{`mainboard`}</strong>{` usually adopts a unit sampling test to verify if the module performance indicators of the power supply of the mainboard meet the design requirements.`}</li>
    </ol>
    <h3 {...{
      "id": "4-manipulation-and-display"
    }}>{`4. Manipulation and Display`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Testing the operational capabilities of any `}<strong parentName="p">{`button, touch input, remote control, and interface`}</strong>{` test is considered relatively simpler functional testing. These are usually done using the aid of a checklist, according to the devices or any logical frameworks. `}</p>
        <ul parentName="li">
          <li parentName="ul">{`The functional testing of `}<strong parentName="li">{`interfaces`}</strong>{` needs to check if the device can `}<strong parentName="li">{`adapt`}</strong>{` to various interface components. The `}<strong parentName="li">{`connectivity`}</strong>{` of the whole device will also be measured through the test.`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The functional testing for the `}<strong parentName="p">{`LED, display, sound, and other functions used to prompt users`}</strong>{` is also relatively simple but relies more on manual work, so it is necessary to consider how to reduce extensive human omissions during the process. `}<strong parentName="p">{`Video recording `}</strong>{`in the workplace is usually adopted.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Sensors`}</strong></p>
      </li>
    </ol>
    <p>{`As sensors are another core module of IOT products, the functional implementation of the sensor is the `}<strong parentName="p">{`most important requirement`}</strong>{`, followed by the general need for `}<strong parentName="p">{`calibration`}</strong>{`.`}</p>
    <p>{`There are so many kinds of sensors, which may be of completely different technical categories, such as optics and chemistry, so different tools and solutions need to be selected depending on the type of sensor.`}</p>
    <p>{`Some sensors with strong independence, that are connected through the interface and can be separately tested, generally use the separation method for component-level calibration and testing, while most units still need to be tested by displaying or uploading data to the terminal.`}</p>
    <p>{`Some analog sensors may need to be calibrated, usually with `}<strong parentName="p">{`physical measurement`}</strong>{`, and some auxiliary instruments (tensile meters, light meters, etc.) or materials (chemicals) may need to be prepared.`}</p>
    <p>{`Combining calibration and functional testing is usually a rather difficult task for designers to do when designing architecture. A complete set of testing systems often needs to be created for products with multiple sensors.`}</p>
    <p>{`While the manufacturing process of IoT products is becoming more and more automated, the gap between the production time and the testing time widens. `}<strong parentName="p"><em parentName="strong">{`Less time is spent on manufacturing, and more time is spent on testing`}</em></strong>{` the product (this doesn't include software testing, which tends to be more time-consuming).`}</p>
    <blockquote>
      <p parentName="blockquote">{`With the advent of the new era of data, the functions of products are more and more complex. `}<strong parentName="p">{`NexPCB always aims to deliver products with the highest quality, fastest speed, and lowest possible cost.`}</strong>{` `}</p>
    </blockquote>
    <p>{`To equip with functional testing requirements, we have prepared a massive deal of equipment and mastered various software and hardware tools. Recently, we obtained battery testers and various types of instruments, designed and made fixtures for downloading and testing, wrote software tools, built laboratories, cooperated with external suppliers for specific testing, and many more.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      